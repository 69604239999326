import React from 'react';
import { Helmet } from "react-helmet"
import MenuBar from '../components/MenuBar';
import { graphql, navigate } from 'gatsby';
import ProjectPreview from '../components/ProjectPreview';
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image';
import '../styles/bag.css'
import Draggable from "react-draggable"
import { StoreContext } from "../context/store-context"
import { LineItem } from '../components/LineItem';
import { formatPrice } from '../utils/format-price';
import Dock from '../components/Dock';

const bag = ({ }) => {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const handleCheckout = () => {
    console.log(checkout.webUrl + " opened + " + checkout.lineItems.length)
    window.open(checkout.webUrl)

  }

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="black" />
        <title>Bag - Byrejjie</title>
        <link rel="canonical" href="https://mysite.com/example" />
      </Helmet>
      <MenuBar />
      <div className='portfolio-container'>
        <Draggable><h1 className='title'>bag</h1></Draggable>
        {emptyCart ? (
          <div className='main'>
            <h1 className='total-title'>your bag is empty</h1>
            <button className='checkout-button' onClick={() => navigate('/shop')}>shop</button>
          </div>) : (
          <div className='main'>

            {checkout.lineItems.map((item) => (
              <LineItem item={item} key={item.id} />
            ))}
            <h1 className='total-title'>Total</h1>
            <h1 className='total-price'>${formatPrice(
              checkout.totalPriceV2.currencyCode,
              checkout.totalPriceV2.amount
            )}</h1>
            <button className='checkout-button' onClick={handleCheckout}>checkout</button>
            <p className='footnote'><i>*all prices are in aud</i></p>
          </div>
        )}



      </div>
      <Dock />
    </main >
  );
};

export default bag;